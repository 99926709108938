'use strict';

import { flow, action, observable, configure, observe, computed, makeObservable, autorun, comparer } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import {mapBlocs} from "../utils/mapBlocs";

import theclaw from '../utils/Api';

/**
 * @typedef {object} BlocMeta
 * @property {string} code - the "code" of the bloc - used in the URL and for object indexing
 * @property {string} id - database id
 * @property {string} name - the "display name" of the bloc
 * @property {string} description
 *
 *
 * @typedef {object} Bloc
 * @property {BlocMeta} bloc
 * @property {Record<string, Collective>} collectives
 *
 *
 * @typedef {object} Collective
 * @property {string} code - the "code" of the collective - used in the URL and for object indexing
 * @property {string} id - database id
 * @property {string} name - the "display name" of the collective
 * @property {string} description
 * @property {Array<ModuleConfigurationEntity>} moduleConfigurationEntities
 * @property {Array<any>} releaseEntities
 * @property {string} releaseNamingDescription
 * @property {boolean} buildSequencingEnabled
 * @property {boolean} releaseNamingEnabled
 *
 * @typedef {object} ModuleConfigurationEntity
 * @property {string} name
 * @property {string} id - database id
 * @property {string} gitUrl
 * @property {boolean} required
 * @property {string} status
 * @property {boolean} buildMonitoringEnabled
 * @property {number} buildPriority
 *
 * @typedef {'modules'|'releases'|'log'} ResourceTab
 */

/*
 * Global data store for the entire The Claw app
 */
class TheClawStore {

    state = States.initial;

    /** @type {string} */
    blocCode;

    /** @type {string} */
    collectiveCode;

    // Collective tab to display (modules, releases, log).
    /** @type {ResourceTab} */
    resourceTab = "releases";

    /** @type {Record<string, Bloc>} */
    blocs;

    constructor() {
        makeObservable(this, {
            state: observable,

            blocCode: observable,
            collectiveCode: observable,
            resourceTab: observable,

            setBlocCode: action,
            setCollectiveCode: action,
            setPageParams: action,
            setResourceTab: action,

            currentBloc: computed,
            currentCollective: computed,
        });
    }

    async mount(params) {
        // Load the data.
        await this.loadBlocs();
    }

    setNavigate = (navigate) => {
        this.navigate = navigate;
        // This function allows us to modify the URL when any of our observables change.
        autorun(() => {
            let pathTokens = [this.blocCode, this.collectiveCode];
            if (this.collectiveCode && this.resourceTab) {
                pathTokens.push(this.resourceTab);
            }
            let path = pathTokens.join('/');
            this.navigate({
                pathname: path,
                search: this.location?.search,
            }, {replace: false});
        });
    }

    setLocation = (location) => {
        this.location = location;
    }

    /**
     * Set the main page parameters using the URL parameters from react-router.
     * @param {object} params
     * @param {string} [params.blocCode]
     * @param {string} [params.collectiveCode]
     * @param {ResourceTab} [params.page]
     */
    setPageParams = (params) => {
        this.setBlocCode(params.blocCode);
        this.setCollectiveCode(params.collectiveCode);
        this.setDefaultBlocAndOrCollective();
        if (params.page) {
            this.setResourceTab(params.page);
        }
    }

    /** @param {ResourceTab} tab */
    setResourceTab = (tab) => this.resourceTab = tab;

    getResourceTab = () => {
        return this.resourceTab
    }

    setBlocCode = (blocCode) => {
        this.blocCode = blocCode;
        this.setDefaultBlocAndOrCollective();
    }

    setCollectiveCode = (collectiveCode) => {
        this.collectiveCode = collectiveCode;
    }

    /**
     * Get the current bloc object.
     */
    getCurrentBloc = () => {
        return this.currentBloc;
    };

    /**
     * Gets the current collective object.
     */
    getCurrentCollective = () => {
       return this.currentCollective;
    }

    /**
     * Gets the collective objects for the given Bloc code as an array.
     */
    getCollectives = (blocCode) => {
        return Object.values(this.blocs[blocCode].collectives);
    }

    /**
     * Gets the list of Bloc objects as an array.
     */
    getBlocs = () => {
        return Object.values(this.blocs).map((b) => {
            return b.bloc;
        });
    }

    setDefaultBlocAndOrCollective = () => {
        // Set some defaults if we don't have bloc and collective codes.
        if (!this.blocs) {
            return;
        }

        if (! this.blocCode) {
            this.setBlocCode(Object.keys(this.blocs)[0]);
        }

        const collectives = Object.keys(this.blocs[this.blocCode].collectives);
        if (!this.collectiveCode || !collectives.includes(this.collectiveCode)) {
            this.setCollectiveCode(collectives[0]);
        }
    }

    /**
     * Load Blocs.
     * @param blocCode If present, the bloc to set as current. Otherwise, ignored.
     * @param collectiveCode If present, and bloc is set, the collective to set as the current. Otherwise, ignored.
     */
    loadBlocs = flow(
    /** @this {TheClawStore} */
    function* (blocCode, collectiveCode) {
        this.state = States.pending;
        try {
            this.blocs = mapBlocs(yield theclaw.listBlocs());

            this.setDefaultBlocAndOrCollective();

            this.state = States.done;
        } catch(error) {
            this.state = States.error;
        }
    }.bind(this));

    get currentBloc() {
        return this.blocs?.[this.blocCode]?.bloc;
    }

    get currentCollective() {
        return this.blocs?.[this.blocCode]?.collectives?.[this.collectiveCode];
    }
}

export default new TheClawStore();
