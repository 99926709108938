'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {configure} from "mobx";
import {observer} from 'mobx-react';

configure({enforceActions: 'always'});

import dateformat from 'dateformat';

import Badge from "react-bootstrap/Badge";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';

import Button from 'react-bootstrap/Button';
import {
    FaRedoAlt
} from "react-icons/fa";

const {SearchBar} = Search;
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone
} from 'react-bootstrap-table2-paginator';

import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';
import logStore from './LogStore';

/**
 * Loads and displays log entries.
 */
class LogEntries extends Component {

    static propTypes = {
        blocCode: PropTypes.string.isRequired,
        collective: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.props = props;
        this.store = logStore;
        this._buildPage = this._buildPage.bind(this);
        this._loadData = this._loadData.bind(this);
    }

    componentDidMount() {
        this._loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check for page changes ...
        const prevCollectiveCode = prevProps.collective.code;
        const newCollectiveCode = this.props.collective.code;
        if (prevCollectiveCode !== newCollectiveCode) {
            this._loadData();
        }
    }

    _loadData() {
        this.store.loadData(this.props.blocCode, this.props.collective.code);
    }

    _buildPage() {

        function timestampFormatter(cell, row) {
            let d = new Date(row.timestamp);
            return (<span style={{whiteSpace:"pre"}}>{dateformat(d, "yyyy-mm-dd HH:MM:ss,l")}</span>);
        }

        function levelFormatter(cell, row) {
            let badgeVariant = null;
            switch (row.level) {
                case 'ERROR': badgeVariant = "danger"; break;
                case 'WARN': badgeVariant = "warning"; break;
                default: badgeVariant = "success"; break;
            }
            return <Badge bg={badgeVariant}>{row.level}</Badge>
        }

        const entries = this.store.getLogEntries(this.props.collectiveId);

        const columns = [
            {
                dataField: 'timestamp',
                text: 'Timestamp',
                formatter: timestampFormatter,
                sort: true
            }, {
                dataField: 'level',
                formatter: levelFormatter,
                text: 'Level'
            }, {
                dataField: 'message',
                text: 'Message'
            }];

        const options = {
            custom: true,
            paginationSize: 10,
            pageStartIndex: 1,
            showTotal: true,
            totalSize: entries.length,
            sizePerPage: 25
        };
        const contentTable = ({paginationProps, paginationTableProps}) => (
            <div className="log-entry-table">
                <ToolkitProvider
                    bootstrap4
                    keyField="index"
                    columns={columns}
                    data={entries}
                    search>
                    {
                        toolkitprops => (
                            <>
                                <div className="d-flex align-items-center mb-3">
                                    <SearchBar {...toolkitprops.searchProps} />
                                    <Button
                                        id="pageRefresh"
                                        title="Page Refresh"
                                        aria-label="Page Refresh"
                                        key={1}
                                        size="sm"
                                        variant={"secondary"}
                                        onClick={this._loadData}>
                                     <FaRedoAlt aria-hidden={true} />
                                    </Button>

                                </div>
                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    rowStyle={ { whiteSpace: "pre-wrap" } }
                                    hover
                                    defaultSorted = {[{
                                        dataField: 'timestamp',
                                        order: 'desc',
                                    }]}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </>
                        )
                    }
                </ToolkitProvider>
                <PaginationListStandalone {...paginationProps} />
            </div>
        );

        return (
            <div>
                <PaginationProvider pagination={paginationFactory(options)} >
                    {contentTable}
                </PaginationProvider>
            </div>
        );
    }


    render() {
        return <SimpleState state={this.store.state}
                            doneComponentBuilder={this._buildPage}
                            name="LogEntryTable"/>
    }
}

export default observer(LogEntries);