'use strict';

import React, {useState, useRef} from 'react';
import {observer} from "mobx-react";
import { useHotkeys } from 'react-hotkeys-hook';

import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import classnames from 'classnames';

import SearchStore from './SearchStore';

import {withRouter} from "@uw-it-sis/lib-react/lib/WithRouter";

/**
 * @import { FuseResult } from 'fuse.js';
 * @import { SearchResult, CollectiveSearchResult, ModuleSearchResult } from './SearchStore';
 */

// Set to false if you need to debug something in the search results
const DEFOCUS_ON_BLUR = true;
const classSearchElem = 'search-elem';

/**
 * This started out based on the search component from course-search, but the react-autosuggest component was a bit
 * heavy duty, and didn't quite fit the model for what I wanted to do here.
 *
 * Over there, the suggestions are more like "text search completion" suggestions that get inserted to the search bar when you
 * select them. Then you click a button to submit your search.
 *
 * Here we don't want to support "free form search text" because, well, it'd be a lot more complicated. And instead of
 * having to pick a suggestion and click a search button, I wanted to be able to tab through the results and just pick
 * one.
 */
let Search = withRouter(observer((props) => {

    /** @type {React.MutableRefObject<HTMLInputElement>} */
    const inputRef = useRef(null);

    // Ctrl+K to open the search modal
    useHotkeys('ctrl+k', () => inputRef.current.select(), {
        preventDefault: true,
        enableOnFormTags: ['input'],
    })

    // Support arrow navigation through the search results
    const [curItemIndex, setCurItemIndex] = useState(-1);
    const prevItemRef = useRef(null);
    const nextItemRef = useRef(null);
    const handleArrowNav = (event) => {
        if ((event.key) == "ArrowDown") {
            event.preventDefault();
            nextItemRef.current?.focus?.();
            setCurItemIndex(Math.min(SearchStore.activeSearchResults.length - 1, curItemIndex + 1));
        } else if ((event.key) == 'ArrowUp') {
            event.preventDefault();
            setCurItemIndex(Math.max(-1, curItemIndex - 1));
            // Focus the searchbar, or the previous item
            curItemIndex === 0 ? inputRef.current?.focus?.() : prevItemRef.current?.focus?.();
        }
    }

    const handleFocus = () => SearchStore.setIsActive(true);
    /**
     * Hide the suggestions when the search input loses focus.
     * @type {React.FocusEventHandler} */
    const handleBlur = (event) => {
        event.currentTarget.parentNode
        // Check if the related target (element losing focus to) is part of the suggestions
        if (DEFOCUS_ON_BLUR && (!event.relatedTarget || !event.relatedTarget.classList.contains(classSearchElem))) {
            SearchStore.setIsActive(false)
        }
    };

    return <Form id='search-form'>
        {/* Search Input */}
        <Form.Control
            type="text"
            ref={inputRef}
            value={SearchStore.query}
            placeholder="Search (ctrl+k)"
            id='search-input'
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            onChange={(e) => SearchStore.setQuery(e.target.value)}
            onFocus={() => { handleFocus(); setCurItemIndex(-1); }}
            onBlur={handleBlur}
            onKeyDown={handleArrowNav}
            className={classnames(classSearchElem, {active: SearchStore.isActive})}
        />

        {/* Search results. Only render if the search is active */}
        {SearchStore.isActive && <ListGroup id='search-suggestions'>
            {SearchStore.activeSearchResults.map((suggestion, index) => (
                <ListGroup.Item
                    // Semantically, this really should be an 'a' I think, so that these are "links' instead of buttons.
                    as="a"
                    href={`#${SearchStore.computeUrlForSearchOption(suggestion.item)}/releases`}
                    key={index}
                    action
                    className={classSearchElem}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    ref={index === curItemIndex + 1 ? nextItemRef : index === curItemIndex - 1 ? prevItemRef : null}
                    onKeyDown={handleArrowNav}
                    onFocusCapture={() => setCurItemIndex(index)}
                    onClick={() => {
                        SearchStore.setIsActive(false);
                        SearchStore.setQuery("");
                    }}
                >
                    <Suggestion result={suggestion} />
                </ListGroup.Item>
            ))}
        </ListGroup> }
    </Form>
}));

/**
 * A Search Suggestion item
 * @param {{result: FuseResult<SearchResult>}} result
 */
const Suggestion = ({result}) => {
    let i = result.item;
    switch (i.type) {
        case 'collective':
            let cResult = /** @type {FuseResult<CollectiveSearchResult>} */ (result);
            return <CollectiveSuggestion result={cResult} />
        case 'module':
            let mResult = /** @type {FuseResult<ModuleSearchResult>} */ (result);
            return <ModuleSuggestion result={mResult} />
    }
}

/** A badge that's the same width, no matter the text */
const SuggestionBadge = ({children, ...props}) =>
    <Badge {...props} style={{ width: '5rem' }} className='me-2' >
        {children}
    </Badge>

/**
 * Render a 'Collective' item in the search results.
 *
 * @param {{ result: FuseResult<CollectiveSearchResult> }} props
 */
const CollectiveSuggestion = ({result}) => {
    let item = result.item;
    let blocText = buildFuseHighlightedText('bloc', item.bloc, result.matches);
    let collectiveText = buildFuseHighlightedText('collective', item.collective, result.matches);
    return <div><SuggestionBadge bg="green-500">collective</SuggestionBadge> {' '} {blocText} {' / '} {collectiveText}</div>
}

/**
 * @param {{ result: FuseResult<ModuleSearchResult> }} props
 */
const ModuleSuggestion = ({result}) => {
    let item = result.item;
    let blocText = buildFuseHighlightedText('bloc', item.bloc, result.matches);
    let collectiveText = buildFuseHighlightedText('collective', item.collective, result.matches);
    let moduleText = buildFuseHighlightedText('module', item.module, result.matches);
    return <div><SuggestionBadge bg="blue-400">module</SuggestionBadge> {' '} {blocText} {' / '} {collectiveText} {' / '} {moduleText}</div>
}


/**
 * Build the highlighted text for a single field in the fuse search results.
 * Pass the key and value of the field you want to highlight, and the array of all matches. There might be no matches for this particular field and so no highlighting will be done.
 * @param {string} fieldName
 * @param {string} fieldText
 * @param {Readonly<Array>} allMatches
 * @returns {React.ReactNode}
 * NOTE I copied this out of app-register (and fixed some things, so prefer this version if/when we ever de-duplicate).
 */
function buildFuseHighlightedText(fieldName, fieldText, allMatches) {
    if (!allMatches.some(match => match.key === fieldName)) {
        return fieldText;
    }

    let parts = [];

    let prevPartEnd = -1;

    // Find the parts of the text that were matched, and highlight them.
    allMatches.filter(match => match.key === fieldName)?.forEach((match, index) => {
        let thisPartStart = match.indices[0][0];
        let thisPartEnd = match.indices[0][1];
        if (thisPartStart !== prevPartEnd) {
            // Add a non-highlight part for the missing section
            parts.push({
                text: fieldText.substring(prevPartEnd + 1, thisPartStart),
                highlight: false
            });
        }

        // Add a highlight part for this match
        parts.push({
            text: fieldText.substring(thisPartStart, thisPartEnd + 1),
            highlight: true
        });

        prevPartEnd = thisPartEnd;
    });

    if (prevPartEnd !== fieldText.length - 1) {
        parts.push({
            text: fieldText.substring(prevPartEnd + 1, fieldText.length),
            highlight: false
        });
    }

    return (
        <>{
            parts?.map((part, index) => {
                return part.highlight ? <strong key={index}>{part.text}</strong> : part.text;
            })
        }</>
    );
}


export default Search;
