'use strict';

import React, {Component, useEffect} from 'react';
import {observer} from "mobx-react";

import Form from 'react-bootstrap/Form';

import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';
import {withRouter} from "@uw-it-sis/lib-react/lib/WithRouter";

import TheClawStore from './TheClawStore';
import Modules from '../modules/Modules';
import Releases from '../releases/Releases';
import LogEntries from "../log/LogEntries";
import CollectiveForm from "../collectives/CollectiveForm";
import BlocForm from "../blocs/BlocForm";
import {capitalize} from "@uw-it-sis/lib-react/lib/Utils";
import ActionUtils from '../utils/ActionUtils';
const {
    UPDATE_BLOC,
    UPDATE_COLLECTIVE,
    NEW_COLLECTIVE,
    NEW_BLOC,
} = ActionUtils.Actions;
import ActionPopupButton from '../components/ActionPopupButton';
import Search from './Search';

/**
 * This is a higher order component that wraps our main component, and makes
 * sure that any changes to the URL trigger a re-render via our mobx store and
 * the store itself will auto-update the URL when any value in the store changes.
 */
const withAutoNav = (Component) => {
    const Wrapper = withRouter((props) => {
        // There's something wrong with this bit: props.location does not update
        // if query params change. This shouldn't affect us in The Claw, but
        // beware if using this code elsewhere.
        useEffect(() => TheClawStore.setLocation(props.location), [props.location]);
        useEffect(() => TheClawStore.setNavigate(props.navigate), []);
        useEffect(() => TheClawStore.setPageParams(props.params), [props.params]);
        return <Component {...props} />;
    });
    return Wrapper;
}

/**
 * The top level component for The Claw. Deals with selecting Blocs and Collectives.
 */
class TheClawApp extends Component {

    constructor(props) {
        super(props);
        this.store = TheClawStore;
        this.currentBloc = null;
        this.currentCollective = null;
    }

    componentDidMount() {
        this.store.mount(this.props.params);
    }

    /**
     * The Blocs dropdown.
     * @param blocs
     * @param currentBlocCode The current bloc code.
     */
    _buildBlocSelector(blocs, currentBlocCode) {
        let options = blocs.map((bloc, index) => {
            return <option key={index} value={bloc.code}>{bloc.name}</option>;
        });

        return (
            <Form.Select
                className="my-2 my-sm-0 me-0 me-sm-3"
                name="blocSelector"
                onChange={e => this.store.setBlocCode(e.target.value)}
                defaultValue={currentBlocCode}>
                {options}
            </Form.Select>
        );
    }

    _buildTabs(blocCode) {
        const collectives = this.store.getCollectives(blocCode);
        const currentCollective = this.store.getCurrentCollective();
        // Sometimes there won't be a current collective (e.g. you just added a Bloc).
        let currentCollectiveCode = null;
        if (currentCollective) {
            currentCollectiveCode = this.store.getCurrentCollective().code;
        }

        // Map collectives and create a tab for each collective. Only build out the body of the current collective.
        const collectivesTabs = collectives.map((collective, index) => {
            return (
                <Tab key={index} eventKey={collective.code} title={collective.name}>
                    { collective.code === currentCollectiveCode && <>
                        <Navbar bg="light" className="rounded-3 px-3 py-2 mb-3">
                            <Navbar.Toggle aria-controls="claw-navbar-nav" />
                            <Navbar.Collapse id="claw-navbar-nav" className="">
                                <Form className="d-flex align-items-center me-3">
                                    <ActionPopupButton action={UPDATE_COLLECTIVE}>
                                        <CollectiveForm mode="edit"
                                                        collective={currentCollective}
                                                        blocCode={this.store.getCurrentBloc().code} />
                                    </ActionPopupButton>
                                </Form>
                                <Nav className="align-items-center">
                                    {['releases', 'modules', 'log'].map((resource, i) =>
                                        <LinkContainer key={i} to={`/${blocCode}/${currentCollectiveCode}/${resource}`}
                                                       isActive={this.store.getResourceTab() === resource}>
                                            <Nav.Link>{capitalize(resource)}</Nav.Link>
                                        </LinkContainer>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        { this.store.getResourceTab() === "modules"  && <Modules blocCode={blocCode} collective={currentCollective}/> }
                        { this.store.getResourceTab() === "releases" && <Releases blocCode={blocCode} collective={currentCollective}/> }
                        { this.store.getResourceTab() === "log"      && <LogEntries blocCode={blocCode} collective={currentCollective}/> }
                    </>}
                </Tab>);
        });

        return(
            <Tabs id="collectives-tabs" className="Xmb-3"
                  activeKey={currentCollectiveCode}
                onSelect={code => this.store.setCollectiveCode(code)}>
                {collectivesTabs}
            </Tabs>
        );
    }

    _buildPage = () => {
        return (
            <>

                <Navbar bg="light" className="rounded-3 px-3 py-2 mb-3">
                    <Navbar.Brand>Bloc</Navbar.Brand>
                    <Navbar.Toggle aria-controls="claw-navbar-nav" />
                    <Navbar.Collapse id="claw-navbar-nav" className="">
                        <Form className="d-flex align-items-center me-3">
                            {this._buildBlocSelector(this.store.getBlocs(), this.store.blocCode)}
                            <ActionPopupButton action={UPDATE_BLOC}>
                                <BlocForm mode="edit" bloc={this.store.getCurrentBloc()} />
                            </ActionPopupButton>
                            <ActionPopupButton action={NEW_COLLECTIVE}>
                                <CollectiveForm mode="add" blocCode={this.store.blocCode}/>
                            </ActionPopupButton>
                        </Form>

                        <Search />

                        <Nav className="ms-auto">
                            <ActionPopupButton action={NEW_BLOC}>
                                <BlocForm mode="add"/>
                            </ActionPopupButton>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                {this._buildTabs(this.store.blocCode)}
            </>
        );
    }

    render() {
        return (
            <SimpleState state={this.store.state} doneComponentBuilder={this._buildPage} name="TheClawApp"/>
        );
    }
}

export default withAutoNav(observer(TheClawApp));
