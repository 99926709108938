'use strict';

import Fuse from "fuse.js";
import { flow, action, observable, configure, observe, computed, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import TheClawStore from './TheClawStore';

/**
 *
 * @typedef {CollectiveSearchResult | ModuleSearchResult} SearchResult
 *
 * @typedef {object} CollectiveSearchResult
 * @property {'collective'} type - the type of search result. Right now the only suggestions are collectives, but maybe we could suggest the release or modules page for a specific collective.
 * @property {string} bloc
 * @property {string} collective
 * @property {string} collectiveCode
 * @property {string} main - This should have the same text as the collective name.
 * @property {string} [page] - this could be releases, modules, logs, etc.
 *
 * @typedef {object} ModuleSearchResult
 * @property {'module'} type - the type of search result.
 * @property {string} bloc
 * @property {string} collective
 * @property {string} collectiveCode
 * @property {string} module
 * @property {string} main - This should have the same text as the module name.
 */

class SearchStore {

    isActive = false;
    query = "";

    constructor() {
        makeObservable(this, {
            isActive: observable,
            query: observable,
            setIsActive: action,
            setQuery: action,

            searchIndex: computed,
            activeSearchResults: computed,
        });
    }

    /** @param {boolean} openState */
    setIsActive = (openState) => this.isActive = openState
    toggle = () => this.setIsActive(!this.isActive)
    /** @param {string} query */
    setQuery = (query) => this.query = query

    /**
     * This returns the "compiled" search index - a list of all the possible search results.
     * 
     * This property is marked as 'computed' so that mobx will recompute it when the upstream collectives data changes,
     * and cache the results otherwise.
     *
     * What this gets us is a sensible behavior during renders that occur before the initial data has been loaded (we
     * compute an empty index), and recomputation with caching later on, all without having to coordinate and trigger a
     * computation manually when the data load from the other store finishes.
     */
    get searchIndex() {

        let collectiveSearchResults = TheClawStore.getBlocs().flatMap(bloc => {
            return TheClawStore.getCollectives(bloc.code).map(collective => {
                /** @type {SearchResult} */
                let option = {
                    type: 'collective',
                    bloc: bloc.code,
                    collective: collective.name,
                    collectiveCode: collective.code,
                    main: collective.code,
                };
                return option;
                // this.baseSearchResults.push(option);
            });
        });

        let moduleSearchResults = TheClawStore.getBlocs().flatMap(bloc => {
            return TheClawStore.getCollectives(bloc.code).flatMap(collective => {
                const modules = collective.moduleConfigurationEntities;
                // If there's only one module in this collective, and it is the same as the collective code, don't suggest it.
                if (modules.length == 1 && modules[0].name == collective.code) {
                    return [];
                }
                return modules.map(module => {
                    /** @type {SearchResult} */
                    let option = {
                        type: 'module',
                        bloc: bloc.code,
                        collective: collective.name,
                        collectiveCode: collective.code,
                        module: module.name,
                        main: module.name,
                    };
                    return option;
                });
            });
        });


        let baseSearchResults = [];
        baseSearchResults.push(...collectiveSearchResults);
        baseSearchResults.push(...moduleSearchResults);

        let fuse = new Fuse(baseSearchResults, {
            includeMatches: true,
            keys: [
                'bloc',
                'collective',
                'module',
                // The main field is a bit of a hack since we're mixing
                // different types of suggestions. Basically, this allows us to
                // prioritize collective suggestions above their modules which
                // by definition will always have the collective text too.
                { name: 'main', weight: 2 },
            ],
        });

        return fuse;
    }

    /**
     * This property is marked as 'computed'. This means we'll recompute search
     * results any time the user query changes.
     * @returns {import('fuse.js').FuseResult<SearchResult>[]}
     */
    get activeSearchResults() {
        return this.searchIndex.search(this.query)
    }

    /**
     * @param {SearchResult} option
     */
    computeUrlForSearchOption(option) {
        return `/${option.bloc}/${option.collectiveCode}`
    }

}

export default new SearchStore();
