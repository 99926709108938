'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {
    FaRedoAlt,
    FaCircleNotch
} from "react-icons/fa";

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';

import {capitalize} from '@uw-it-sis/lib-react/lib/Utils';
import SimpleState from "@uw-it-sis/lib-react/lib/SimpleState";

import FutureTab from './FutureTab';
import ActiveTab from './ActiveTab';
import HistoricalTab from './HistoricalTab';
import ReleasesStore from "./ReleasesStore";
import {Container} from "react-bootstrap";
import appStore from "@uw-it-sis/lib-react/lib/AppStore";

/*
 * The main releases page
 */
class Releases extends Component {

    static propTypes = {
        blocCode: PropTypes.string.isRequired,
        collective: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.store = ReleasesStore;
        this._buildPage = this._buildPage.bind(this);
    }

    componentDidMount() {
        this.store.mount(this.props.blocCode, this.props.collective);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check for changes ...
        const prevBlocCode = prevProps.blocCode;
        const newBlocCode = this.props.blocCode;
        const prevCollectiveCode = prevProps.collective.code;
        const newCollectiveCode = this.props.collective.code;
        if (newBlocCode !== prevBlocCode || prevCollectiveCode !== newCollectiveCode) {
            this.store.mount(this.props.blocCode, this.props.collective);
        }
    }

    componentWillUnmount() {}

    _buildRefreshOptions() {
        let items = this.store.AutoRefreshItems;

        // Add a 1s refresh for local envs, cuz I'm impatient
        if (appStore.getEnvironment() == "local") {
            items = [
                {label:"1s", milliseconds: 1000},
                ...items,
            ];
        }

        return items.map((option, index) => {
            return <option key={index} value={option.milliseconds}>{option.label}</option>
        });
    }

    onClickReloadPage = () => {
        this.store.loadReleases(false);
    }

    onSetAutoRefresh = (e) => {
        // Off is hardcoded down in the selector widget ...
        if (e.target.value === "off") {
            this.store.stopAutoRefresh();
        } else {
            // Need to clear the old timer before starting a new one.
            this.store.stopAutoRefresh();
            this.store.startAutoRefresh(e.target.value);
        }
    }

    _buildPage() {

        return (
            <>
                <Navbar className="mb-2">
                    <Container>
                    <Nav className="align-items-center">
                        <Form.Check
                            className="ms-4 mb-0"
                            id="includeInactive"
                            name="includeInactive"
                            checked={this.store.includeInactive}
                            onChange={this.store.toggleIncludeInactive}
                            label="Include Inactive" />
                    </Nav>
                </Container>
                    <Container>
                        {this.store.busyState !== States.done && <BusySpinner/>}
                    </Container>
                    <Container>
                    <Navbar.Collapse className="justify-content-end">
                        <Form>
                            <Form.Label className="align-items-center" style={{paddingRight: '0.2rem'}}
                                        htmlFor="pageRefresh">Page Refresh</Form.Label>
                            <ButtonGroup>
                                <Button
                                    id="pageRefresh"
                                    key={1}
                                    size="sm"
                                    variant={"secondary"}
                                    onClick={this.onClickReloadPage}>
                                    <FaRedoAlt aria-hidden={true} />
                                </Button>
                                <Form.Select onChange={this.onSetAutoRefresh}>
                                    <option key={-1} value={"off"}>Off</option>
                                    {this._buildRefreshOptions()}
                                </Form.Select>
                            </ButtonGroup>
                        </Form>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Tabs
                    activeKey={this.store.tab}
                    onSelect={this.store.setTab}
                    className="custom-tabs">
                    <Tab key="future" eventKey="future" title={capitalize("future")}>
                        <FutureTab
                            releases={this.store.getFutureReleases()}
                            collective={this.props.collective}
                            blocCode={this.props.blocCode} />
                    </Tab>
                    <Tab key="active" eventKey="active" title={capitalize("active")}>
                        <ActiveTab
                            releases={this.store.getActiveReleases()}
                            collective={this.props.collective}
                            blocCode={this.props.blocCode} />
                    </Tab>
                    <Tab key="historical" eventKey="historical" title={capitalize("historical")}>
                        <HistoricalTab
                            releases={this.store.getHistoricalReleases()} />
                    </Tab>
                </Tabs>
            </>
        );
    }

    render() {
        // Instead of rendering a spinner, we'll disable all of the buttons
        // when state is pending. This happens automatically in ActionButton
        // (though probably shouldn't)
        return (
            <SimpleState state={this.store.state}
                doneComponentBuilder={this._buildPage}
                pendingComponentBuilder={this._buildPage}
                name="Releases"/>
        );
    }
}

function BusySpinner() {
    return (
        <div className="text-muted d-flex align-items-center space-x-2" style={{padding: '0.3rem 0.75rem'}}>
            <FaCircleNotch aria-hidden={true} className="icon-spin" />
            <small className="fw-bold">Busy ...</small>
        </div>
    )
}

export default observer(Releases);
