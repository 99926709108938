'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {isEqual} from 'lodash';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import CollectiveFormStore from './CollectiveFormStore';

import {
    ClawModal,
    ClawModalHeader,
    ClawModalBody,
    ClawModalFooter,
    ClawForm,
} from '../components/ClawModalLib';

/*
 * This Form is used to add or edit collectives
 */
class CollectiveForm extends Component {

    static propTypes = {
        mode: PropTypes.string.isRequired,
        blocCode: PropTypes.string.isRequired,
        collective: PropTypes.object, // null if we're creating a new collective
    }

    constructor(props) {
        super(props);
        this.store = new CollectiveFormStore();
    }

    componentDidMount() {
        this.store.mount(this.props.mode, this.props.collective, this.props.blocCode);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Re-mount the store if the collective has been changed
        const prevBlocStore = prevProps.bl

        if (!isEqual(prevProps.collective, this.props.collective)) {
            this.store.mount(this.props.mode, this.props.collective, this.props.blocCode);
        }
    }

    /**
     * Handle input widget changes.
     */
    handleFormChange = (e) => {
        let property = e.target.name;
        let value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        this.store.updateFormData(property, value);
    }

    _buildModalBody() {
        let errorMessage = null;
        if (this.store.getErrorMessage()) {
            errorMessage =
                <Alert variant={"danger"} dismissible>
                    <ul>{this.store.getErrorMessage()}</ul>
                </Alert>;
        }

        let releaseNamingDescriptionWidget = null;

        if (this.store.formData.releaseNamingEnabled) {
            releaseNamingDescriptionWidget =
                <Form.Group controlId="description" >
                    <Form.Label>Description</Form.Label>
                        <Form.Control
                                name="releaseNamingDescription"
                                type="text"
                                value={this.store.formData.releaseNamingDescription}
                                placeholder="Release Naming Convention Description"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback/>
                        <Form.Text>The naming convention to be used for releases within this collective.</Form.Text>
                    </Form.Group>
        }

        return (
            <>
                {errorMessage}
                <ClawForm
                    onSubmit={this.store.submit}
                    state={this.store.state} >
                    {/*
                      * COLLECTIVE CODE
                      */}
                    <Form.Group controlId="code" >
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                                required
                                name="code"
                                type="text"
                                value={this.store.formData.code}
                                placeholder="foo"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback />
                        <Form.Text>The unique collective code. This will be used in repo names and The Claw's apis.</Form.Text>
                    </Form.Group>

                    {/*
                      * COLLECTIVE NAME
                      */}
                    <Form.Group controlId="name" >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                                required
                                name="name"
                                type="text"
                                value={this.store.formData.name}
                                placeholder="Foo"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback />
                        <Form.Text>The friendly name of the collective as it is displayed in The Claw.</Form.Text>
                    </Form.Group>

                    {/*
                      * DESCRIPTION
                      */}
                    <Form.Group controlId="description" >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                                name="description"
                                type="text"
                                value={this.store.formData.description}
                                placeholder="Description"
                                onChange={this.handleFormChange} />
                        <Form.Control.Feedback/>
                        <Form.Text>A short description of this collective.</Form.Text>
                    </Form.Group>

                    {/*
                      * BUILD SEQUENCING
                      */}
                    <Form.Group controlId="buildSequencingEnabled" >
                        <Form.Label>Build Sequencing Enabled</Form.Label>
                        <Form.Check
                            type="switch"
                            id="buildSequencingEnabled">
                            <Form.Check.Input type="checkbox"
                                name="buildSequencingEnabled"
                                checked={this.store.formData.buildSequencingEnabled}
                                onChange={this.handleFormChange} />
                            <Form.Check.Label>Whether this collective will builds in sequences during promotions.
                                Disable to promote all modules at the same time.</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>

                    {/*
                      * RELEASE NAMING
                      */}
                    <Form.Group controlId="releaseNamingEnabled" >
                        <Form.Label>Release Naming Enabled</Form.Label>
                        <Form.Check
                            type="switch"
                            id="releaseNamingEnabled">
                            <Form.Check.Input type="checkbox"
                                name="releaseNamingEnabled"
                                checked={this.store.formData.releaseNamingEnabled}
                                onChange={this.handleFormChange} />
                            <Form.Check.Label>Whether this collective supports assigning names to releases.</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>

                    {/*
                      * RELEASE NAMING DESCRIPTION
                      */}
                    {releaseNamingDescriptionWidget}

                </ClawForm>
            </>
        )
    }

    render() {
        let modalTitle;
        switch(this.store.mode) {
            case CollectiveFormStore.MODE.ADD: modalTitle = `Add Collective to bloc '${this.props.blocCode}'`; break;
            case CollectiveFormStore.MODE.EDIT: modalTitle = `Edit Collective`; break;
        }

        return (
            <ClawModal id="CollectiveFormModal">
                <ClawModalHeader title={modalTitle}/>
                <ClawModalBody>
                    {this._buildModalBody()}
                </ClawModalBody>
                <ClawModalFooter
                    onSubmit={this.store.submit}
                    onReset={this.store.reset}
                    loadingState={this.store.state}
                    canSubmit={this.store.isValid()}
                    />
            </ClawModal>
        )
    }
}

export default observer(CollectiveForm);
